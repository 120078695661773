import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { hasFuncs, isPmax3Order, notSettleOrder } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { L1ObjectForm } from './L1ObjectForm/L1ObjectForm';
import { useCreateL1ObjectFormModel, useEditL1ObjectFormModel } from './L1ObjectForm/L1ObjectFormModel';
import { L1ObjectDetail } from './L1ObjectDetail/L1ObjectDetail';
import { Order } from 'core/order/Order';
import { AddonFeatureManager } from 'core';
import _ from 'lodash';
import { DefaultOrderManager } from 'core/order/OrderManager';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { useCallAPI } from 'hooks/useCallAPI';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';

export const L1Objects: React.FC<RouteComponentProps<any> & {
  order: Order,
  addonFeatureManager: AddonFeatureManager
}> = (props) => {

  const [order, setOrder] = useState(props.order);
  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    setOrder(props.order);
  }, [props.order]);

  useEffect(() => {
    const orderManager = new DefaultOrderManager();
    if (props.location.state && _.get(props.location.state, 'refreshOrder', false)) {
      callAPIs([
        orderManager.getOrder.bind(orderManager, order.orderNumber)
      ], newOrder => {
        setOrder(newOrder);
      });
      props.history.replace({ ...props.history.location, state: undefined });
    }
  }, [order.orderNumber, props.location.state, props.history, callAPIs]);

  const renderL1ObjectDetailView = (routeProps) => {
    if (_.get(props.location.state, 'refreshOrder', false)) {
      return <div/>;
    }
    const l1ObjectId = routeProps.match.params.l1ObjectId;
    return (
      <L1ObjectDetail
        l1ObjectId={l1ObjectId}
        order={order}
        addonFeatureManager={props.addonFeatureManager}
      />
    );
  };

  const renderCreateL1ObjectView = () => {
    const useModel = () => useCreateL1ObjectFormModel(order, props.addonFeatureManager);
    return (
      <L1ObjectForm
        useModel={useModel}
      />
    );
  };

  const renderEditL1ObjectView = (routeProps) => {
    const l1ObjectId = routeProps.match.params.l1ObjectId;
    const renderRedirect = () => <Redirect to={`${routeProps.match.url}/${ROUTE_PATH.ERROR403}`} />;
    const useModel = () => useEditL1ObjectFormModel(order, l1ObjectId, props.addonFeatureManager, _.get(routeProps.location.state, 'l2ObjectList'));
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)
          .and(isPmax3Order(order.id))
          .and(notSettleOrder(order))}
        renderOtherwise={renderRedirect}
      >
        <L1ObjectForm useModel={useModel} />
      </PermissionChecker>
    );
  };

  const redirectToOrderL1ObjectDetail = (props) => {
    const match = props.match.url.match(/\/orders\/\d+\//);
    return <Redirect to={`${match[0].slice(0, -1)}`} />;
  };

  if (loading) {
    return <LoadingIndicator/>;
  }

  return (
    <Switch>
      <RouteWithPermission
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE).and(isPmax3Order(props.order.id))}
        redirectPath={`${props.match.url}/new/${ROUTE_PATH.ERROR403}`}
        path={`${props.match.url}/new`}
        render={renderCreateL1ObjectView}
      />
      <Route
        exact
        path={`${props.match.url}/:l1ObjectId/edit`}
        render={renderEditL1ObjectView}
      />
      <Route
        path={`${props.match.url}/:l1ObjectId`}
        render={renderL1ObjectDetailView}
      />
      <Route
        render={redirectToOrderL1ObjectDetail}
      />
    </Switch>
  );
};
