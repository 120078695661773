import { SummaryData, SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { PmpFormType } from 'core/pmp/Pmp';
import { camelCase, compact, defaultTo } from 'lodash';
import i18n from 'i18n';
import { useCoreContext } from 'contexts/coreContext';

export function getPmpSummaryData (
  pmp: PmpFormType | undefined,
  spaceOptions: SelectOptions[],
  currency: string,
  errors?: { [key: string]: string | JSX.Element }
): SummaryData[] {

  if (!pmp) return [];

  const agencyI18nKey = `agency.name.${pmp.adAgencyIds[0]}`;
  const agencySummary = i18n.exists(agencyI18nKey)
    ? i18n.t<string>(agencyI18nKey)
    : i18n.t<string>('pmp.labels.noAgency');
  const spacesSummary = pmp.spaces
    .map(space => {
      const option = spaceOptions.find(option => option.value === space);
      return option ? option.label : space;
    })
    .join('\n');

  const getColor = (key: string) => (errors && errors[key] ? SummaryTitleColor.RED : undefined);
  return compact([
    {
      label: i18n.t<string>('pmp.labels.agency'),
      value: agencySummary
    },
    {
      label: i18n.t<string>('pmp.labels.name'),
      value: pmp.name
    },
    {
      label: i18n.t<string>('pmp.labels.budget'),
      value: `${currency} ${pmp.budget}`,
      valueColor: getColor('budget'),
      hint: errors?.budget,
      hintColor: getColor('budget')
    },
    {
      label: i18n.t<string>('pmp.labels.duration'),
      value: `${pmp.startTime} ~ ${pmp.endTime}`,
      valueColor: getColor('dayRange'),
      hint: errors?.dayRange,
      hintColor: getColor('dayRange')
    },
    {
      label: i18n.t<string>('pmp.labels.pmpType'),
      value: i18n.t<string>(`pmp.pmpTypes.${camelCase(pmp.pmpType)}`)
    },
    {
      label: i18n.t<string>('pmp.labels.spaces'),
      value: spacesSummary
    }
  ]);
}

export const usePmpSummaryModel = (
  pmp: PmpFormType | undefined,
  spaceOptions: SelectOptions[],
  errors?: { [key: string]: string }
): SummaryData[] => {
  const core = useCoreContext();
  const currency = defaultTo(core?.accountManager?.localeMeta?.currency, 'NTD');

  if (!pmp) return [];

  return getPmpSummaryData(pmp, spaceOptions, currency, errors);
};
