import { Pmp, PmpFormType, PmpsWithPagination } from './Pmp';
import moment from 'moment';
import { Pageable } from 'ws/Pageable';
import { PmpWebService, RestfulPmpWebService } from 'ws/PmpWebService';

export interface PmpManager {
  getPmp: (pmpId: number) => Promise<Pmp>;
  getPmpList: (pageable: Pageable, search: string, status: number[]) => Promise<PmpsWithPagination>;
  createPmp: (pmp: PmpFormType) => Promise<void>;
  updatePmp: (pmp: Pmp) => Promise<void>;
  updatePmpState (pmpIds: number[], state: 'activate' | 'deactivate'): Promise<void>;
  getPmpsWithin (startTime: string, endTime: string): Promise<Pmp[]>;
  getDateDescriptions (startTime: string, endTime: string): string[];
  getPmpsOfOrder: (orderId: number, status: number[]) => Promise<Pmp[]>;
}

export class DefaultPmpManager implements PmpManager {

  webService: PmpWebService;

  constructor (webService: PmpWebService = new RestfulPmpWebService()) {
    this.webService = webService;
  }

  getPmp (pmpId: number) {
    return this.webService.getPmp(pmpId);
  }

  getPmpList (pageable: Pageable, search: string = '', status: number[] = []) {
    return this.webService.getPmps(pageable, search, status);
  }

  createPmp (pmp: PmpFormType) {
    return this.webService.createPmp(pmp);
  }

  updatePmp (pmp: Pmp) {
    return this.webService.updatePmp(pmp);
  }

  updatePmpState (pmpIds: number[], state: 'activate' | 'deactivate') {
    return this.webService.updatePmpState(pmpIds, state);
  }

  getPmpsWithin (startTime: string, endTime: string) {
    return this.webService.getPmpsWithin(startTime, endTime);
  }

  getDateDescriptions (startTime: string, endTime: string) {
    const startDate = moment(startTime).startOf('day');
    const endDate = moment(endTime).startOf('day');
    let descriptions: string[] = [];
    const padStart = (num: number) => num.toString().padStart(2, '0');
    while (startDate.isSameOrBefore(endDate)) {
      const descriptionsOfDate = `${startDate.year()}-${padStart(startDate.month() + 1)}-${padStart(startDate.date())}`;
      descriptions = descriptions.concat(descriptionsOfDate);
      startDate.add(1, 'day');
    }
    return descriptions;
  }

  async getPmpsOfOrder (orderId: number, status: number[] = []) {
    const pmpList = await this.webService.getPmpsOfOrder(orderId, status);
    return pmpList.filter(pmp => !pmp.campaignId);
  }
}
